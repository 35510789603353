import React from 'react';
import styled from 'styled-components';
import appConfig from '../../../../../../app-config';
import {Title1, Title2, Title3} from '../../../../../components/Typography';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 75%;
  margin: auto;
  justify-content: center;
  margin-top: 28px;
`;

const ContentDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 16px;
  margin-bottom: 8px;
  text-align: center
`;

function LandingPage(props) {
  return (
    <Wrapper>
      <ContentDiv>
        <img
          src={appConfig.searchLandingPageIcon}
          style={{fontSize: '30px', color: '#737a87'}}
        />
      </ContentDiv>
      <ContentDiv>
        <Title1 style={{color: '#737a87'}}>Search</Title1>
      </ContentDiv>
      <ContentDiv>
        <Title3 style={{color: '#737a87'}}>
          {appConfig.searchBarPlaceholder
            ? `Search for ${appConfig.searchBarPlaceholder.toLowerCase()} accross all locations`
            : `Search for dishes accross all locations`}
        </Title3>
      </ContentDiv>
    </Wrapper>
  );
}

export default LandingPage;
